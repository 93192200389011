/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DashboardReportDto {
  /**
   * id
   */
  id: number;
  /**
   * id
   */
  dashboard_id: number;
  /**
   * \"report name\"
   */
  report_name: string;
  /**
   * \"lng\"
   */
  lng: string;
  /**
   * \"dashboard_name\"
   */
  dashboard_name: string;
  /**
   * \"REQUESTED or CREATED\"
   */
  status: DashboardReportDto.StatusEnum;
  /**
   * \"Name of .pdf file that contains report\"
   */
  s3_filename_pdf: string;
  /**
   * \"Name of .csv file that contains report\"
   */
  s3_filename_csv: string;
  /**
   * \"Name of .json file that contains report\"
   */
  s3_filename_json: string;
  /**
   * Creation date
   */
  requested_at?: string;
  /**
   * Creation date
   */
  requested_by?: string;
  /**
   * Creation date
   */
  generated_at?: string;
  /**
   * Accepted file format for dashboard report
   */
  file_type: DashboardReportDto.FileTypeEnum;
}
export namespace DashboardReportDto {
  export type StatusEnum = 'REQUESTED' | 'GENERATING' | 'CREATED' | 'FAILED';
  export const StatusEnum = {
    REQUESTED: 'REQUESTED' as StatusEnum,
    GENERATING: 'GENERATING' as StatusEnum,
    CREATED: 'CREATED' as StatusEnum,
    FAILED: 'FAILED' as StatusEnum,
  };
  export type FileTypeEnum = 'pdf' | 'csv' | 'json';
  export const FileTypeEnum = {
    Pdf: 'pdf' as FileTypeEnum,
    Csv: 'csv' as FileTypeEnum,
    Json: 'json' as FileTypeEnum,
  };
}
