/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DataRequestValueItemDto {
  /**
   * id
   */
  id?: number;
  /**
   * Logger identifier
   */
  logger_id: number;
  /**
   * Value data identifier
   */
  value_id: number;
  /**
   * Series offset
   */
  offset?: number;
  /**
   * Consumption or state
   */
  consumption?: boolean;
}
