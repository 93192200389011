import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LoginComponent } from './layout-login/login/login.component';
import { LoginLayoutComponent } from './layout-login/login-layout/login-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LogoutComponent } from './layout-login/logout/logout.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { VerifyEmailComponent } from './layout-login/verify-email/verify-email.component';

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent,
      },
    ],
  },
  {
    path: 'policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'public-dashboard/:id',
    component: DashboardComponent,
    data: { pageName: 'DASHBOARD.DASHBOARD', public: true },
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
      },
      {
        path: 'main',
        redirectTo: 'profile',
        // component: MainComponent,
        // data: { pageName: 'NAVBAR.MAIN' },
      },
      {
        path: 'tree',
        loadChildren: () =>
          import('./pages/tree/tree-routing.module').then((m) => m.TreeRoutingModule),
        data: { pageName: 'NAVBAR.TREE' },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile-routing.module').then((m) => m.ProfileRoutingModule),
        data: { pageName: 'NAVBAR.PROFILE', treeDisplay: false },
      },
      {
        path: 'tree/gp',
        loadChildren: () =>
          import('./pages/tree/gp/gp-routing.module').then((m) => m.GpRoutingModule),
        data: { pageName: 'NAVBAR.GP_LIST' },
      },
      {
        path: 'logger',
        loadChildren: () =>
          import('./pages/logger/logger-routing.module').then((m) => m.LoggerRoutingModule),
        data: { pageName: 'NAVBAR.LOGGER.CONFIG' },
      },
      // {
      //   path: 'data',
      //   loadChildren: () =>
      //     import('./pages/data/data-routing.module').then((m) => m.DataRoutingModule),
      //   data: { pageName: 'NAVBAR.DATA' },
      // },
      {
        path: 'organization',
        loadChildren: () =>
          import('./pages/organization/organization-routing.module').then(
            (m) => m.OrganizationRoutingModule,
          ),
        data: { pageName: 'NAVBAR.ORGANIZATION' },
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import('./pages/invoice/invoice-routing.module').then((m) => m.InvoiceRoutingModule),
        data: { pageName: 'NAVBAR.INVOICE' },
      },
      {
        path: 'dashboards',
        loadChildren: () =>
          import('./pages/dashboard/dashboard-routing.module').then(
            (m) => m.DashboardRoutingModule,
          ),
        data: { pageName: 'DASHBOARD.LIST' },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./pages/reports/reports-routing.module').then((m) => m.ReportsRoutingModule),
        data: { pageName: 'DASHBOARD.LIST' },
      },
    ],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
