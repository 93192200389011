/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LanguageDto } from './languageDto';

export interface LanguageListResponseDto {
  /**
   * Table item count
   */
  cnt: number;
  /**
   * Page size
   */
  page_size: number;
  /**
   * Page index
   */
  page_index: number;
  /**
   * Response item array
   */
  data: Array<LanguageDto>;
}
