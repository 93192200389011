import { uplotShowMessage } from '../utils/uplotShowMessage';

export function uplotMousePlugin(opts) {
  const factor = opts.factor || 0.75;
  return {
    hooks: {
      ready: (u) => {
        const over = u.over;
        // const rect = over.getBoundingClientRect();
        const drag = u.cursor.drag;

        const defaultCursor = over.style.cursor;
        over.addEventListener('mousedown', (e) => {
          if (u.loadingElement.isBlocked()) return;
          if (e.button == 1 || e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();

            if (u.cursor.drag.x == true) drag._x = true;
            drag.x = false;
            const left0 = e.clientX;

            const scXMin0 = u.scales.x.min;
            const scXMax0 = u.scales.x.max;

            const xUnitsPerPx = u.posToVal(1, 'x') - u.posToVal(0, 'x');

            over.style.cursor = 'grabbing';

            function onMove(event) {
              event.preventDefault();
              drag.x = false;

              //block if loading?
              if (u?.loadingElement?.isBlocked()) onUp();

              const left1 = event.clientX;

              const dx = xUnitsPerPx * (left1 - left0);

              let nMin = scXMin0 - dx;
              let nMax = scXMax0 - dx;

              u.setScale('x', {
                min: nMin,
                max: nMax,
              });
            }

            function onUp() {
              document.removeEventListener('mousemove', onMove);
              document.removeEventListener('mouseup', onUp);
              over.style.cursor = defaultCursor;
              drag.x = drag._x;
            }

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
          }
        });

        // let scrollMessageShown = false;
        over.addEventListener('wheel', (e) => {
          if (e.buttons == 4) e.preventDefault();

          if (u.loadingElement.isBlocked()) return;
          if (!e.ctrlKey) {
            uplotShowMessage(over);
            return;
          }
          e.preventDefault();

          const { left } = u.cursor;

          const leftPct = left / over.offsetWidth;

          const xVal = u.posToVal(left, 'x');
          const oxRange = u.scales.x.max - u.scales.x.min;

          const nxRange = e.deltaY < 0 ? oxRange * factor : oxRange / factor;
          let nxMin = xVal - leftPct * nxRange;
          let nxMax = nxMin + nxRange;

          u.batch(() => {
            u.setScale('x', {
              min: nxMin,
              max: nxMax,
            });
          });
        });
      },
    },
  };
}
