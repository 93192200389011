import { SeriesCalculationMode } from '../models/SeriesCalculationMode';

export function uplotMinMaxAvg() {
  return {
    hooks: {
      draw: (u) => {
        const seriesCount = u.series.slice(1).length;
        for (let i = 0; i < seriesCount; i++) drawFunctionsForSerie(u, i);
      },
      setCursor: (u) => {
        const seriesCount = u.series.slice(1).length;
        for (let i = 0; i < seriesCount; i++) calculate(u, i);
      },
    },
  };
}

const dashMin = [5, 5];
const dashMax = [10, 15];

function getCurrentViewData(u, i) {
  //TODO can be optimized to get indexes only once for all series
  let firstIndex, lastIndex;

  const selectionData = getSelection(u);
  if (selectionData) {
    [firstIndex, lastIndex] = selectionData;
  } else {
    firstIndex = u.data[0].findIndex((d) => d >= u.scales.x.min);
    lastIndex = u.data[0].findLastIndex((d) => d <= u.scales.x.max);
  }
  const seriesData = u.data.slice(1)[i];
  return seriesData.slice(firstIndex, lastIndex + 1);
}

function getSelection(u) {
  const [selectStart, selectEnd] = [u.select.left, u.select.left + u.select.width];
  if (selectStart == null || !selectEnd) {
    u.selection = null;
    return null;
  }

  const valStart = u.posToVal(selectStart, 'x');
  const valEnd = u.posToVal(selectEnd, 'x');
  const firstIndex = u.data[0].findIndex((d) => d >= valStart); //&& self[i + 1] < valEnd
  // const lastIndex = u.data[0].findIndex((d, i, self) => d >= valEnd) - 2;
  // const lastIndex = u.data[0].findLastIndex((d, i, self) => d - self[i - 1] <= valEnd - d);
  const lastIndex = u.data[0].findLastIndex((d) => d <= valEnd);

  // console.log(`firstIndex: ${firstIndex}, lastIndex: ${lastIndex}`);
  if (firstIndex == -1 || lastIndex == -1 || firstIndex > lastIndex) {
    u.selection = { min: null, max: null };
    return [-1, -1];
  }
  // const lastIndex = u.data[0].findLastIndex((d) => d <= valEnd);'
  u.selection = { min: u.data[0][firstIndex], max: u.data[0][lastIndex] };
  // console.log('-> u.selection', u.selection);
  return [firstIndex, lastIndex];
}

// function getAvg(data) {
//   return getTotal(data) / data.length;
// }

function getTotal(data) {
  return data.filter((o) => o != null).reduce((acc, cur) => Number(acc) + Number(cur), 0);
}

function getMinMax(data) {
  let max = data[0];
  let min = data[0];
  for (let i = 1, l = data.length; i < l; ++i) {
    if (data[i] == null) continue;
    if (data[i] > max) max = data[i];
    if (data[i] < min) min = data[i];
  }
  return { min, max };
}

function calculate(u, i) {
  const s = u.series[i + 1];

  const currentViewData = getCurrentViewData(u, i).filter((o) => o != null);

  const { min, max } = getMinMax(currentViewData);
  const total = getTotal(currentViewData);
  if (s.mode == SeriesCalculationMode.CONSUMPTION_CALC) s.total = total;

  s.avg = currentViewData.length > 0 ? total / currentViewData.length : null;
  s.max = max;
  s.min = min;
}

function drawFunctionsForSerie(u, i) {
  const s = u.series[i + 1];
  const stroke = s._stroke;
  if (s.show == false) return;
  calculate(u, i);

  if (u._userConfig.showAvgLines) {
    const avgY = u.valToPos(s.avg, 'y', true);
    drawAvg(u, avgY, stroke);
  }

  if (u._userConfig.showMinLines)
    drawLine(u, { y: u.valToPos(s.min, 'y', true), strokeStyle: stroke, lineDash: dashMin });
  if (u._userConfig.showMaxLines)
    drawLine(u, { y: u.valToPos(s.max, 'y', true), strokeStyle: stroke, lineDash: dashMax });
}

function drawAvg(u, y, stroke) {
  drawLine(u, { y, strokeStyle: stroke });
  drawLabel(u, { y, label: 'avg', fill: stroke });
}

function drawLine(u, opts: LineOptions) {
  const ctx = u.ctx;
  ctx.save();

  const x0 = u.bbox.left;
  const x1 = u.bbox.left + u.bbox.width;
  const y = opts.y;

  ctx.beginPath();
  ctx.strokeStyle = opts.strokeStyle;

  if (opts.lineDash) ctx.setLineDash(opts.lineDash);
  ctx.moveTo(x0, y);
  ctx.lineTo(x1, y);
  ctx.stroke();
  ctx.restore();
}

function drawLabel(u, opts: LabelOptions) {
  const textOffset = opts.textOffset ?? 3;
  const ctx = u.ctx;
  ctx.save();

  const x = u.bbox.left;
  const y = opts.y;
  ctx.font = '9px serif';
  const metrics = ctx.measureText(opts.label);
  const textWidth = metrics.width;
  const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

  const height = textHeight + textOffset * 2;
  const width = textWidth + textOffset * 4;

  ctx.fillStyle = opts.fill;
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x, y + height);
  ctx.lineTo(x + (width * 3) / 4, y + height);
  ctx.lineTo(x + width, y);
  ctx.fill();

  ctx.fillStyle = 'black';
  ctx.fillText(opts.label, x + textOffset + textWidth, y + textHeight);
  ctx.restore();
}
interface LineOptions {
  y: number;
  strokeStyle: string;
  lineDash?: number[]; //[5,5]
}

interface LabelOptions {
  y: number;
  label: string;

  fill: string;

  textOffset?: number;
}
