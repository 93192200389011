import { Injectable } from '@angular/core';
import {
  DbConfigDto,
  DeviceValuesService,
  ListFilterRequestDto,
  LoggerDto,
  LoggerListResponseDto,
} from '../../../api-main';
import { LoggerService as ApiLoggerService } from '../../../api-main/api/logger.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../shared/services/config.service';
import { Observable } from 'rxjs';
import { DashboardService } from '../dashboard/services/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public savedFilter: ListFilterRequestDto = {
    filter: [],
    sort: [],
    page_size: 25,
    sort_direction: 'asc',
    page_index: 0,
  };

  // selectedTab: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private loggerService: ApiLoggerService,
    private http: HttpClient,
    private deviceValuesService: DeviceValuesService,
    private dashboardService: DashboardService,
  ) {
    this.loggerService = new ApiLoggerService(this.http, ConfigService.getApiConfig(), undefined);
    this.deviceValuesService = new DeviceValuesService(
      this.http,
      ConfigService.getApiConfig(),
      undefined,
    );
  }

  private injectDashboardId(body: ListFilterRequestDto) {
    if (!body) return;
    const dashboard = this.dashboardService.dashboard;
    if (!dashboard) return;
    body.dashboard_id = dashboard.id;
  }

  public getLoggerList(
    body: ListFilterRequestDto = null,
    injectDashboard = true,
  ): Observable<LoggerListResponseDto> {
    if (injectDashboard) this.injectDashboardId(body);
    return this.loggerService.loggerControllerList(body);
  }

  public postLogger(item: LoggerDto): Observable<DbConfigDto> {
    return this.loggerService.loggerControllerSave(item);
  }

  public deleteLogger(id) {
    return this.loggerService.loggerControllerDelete(id);
  }

  public getLogger(id) {
    return this.loggerService.loggerControllerGet(id);
  }

  public getLoggerSSL(id) {
    return this.loggerService.loggerControllerGetSSL(id);
  }

  public postRefreshCode(id: number) {
    return this.loggerService.loggerControllerCodeRefresh(id);
  }
}
