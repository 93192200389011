import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { AuthConfig } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  /**
   * Response with parameters for Auth Module, set application title
   */
  static getAuthConfig(): AuthConfig {
    const cfg = env.oem.find((item) => item.domain === window.location.origin);
    // console.log('-> cfg', cfg);
    if (!cfg) {
      console.error('Configuration not found for origin ', window.location.origin, env.oem);
      return null;
    }
    window.document.title = cfg.title;
    return {
      domain: cfg.auth.domain,
      clientId: cfg.auth.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [
          {
            //TODO check if this not messing up - it's required for dashboard generation
            uri: '*',
            allowAnonymous: true,
          },
          {
            uri: cfg.api + '/dict/language/list',
            allowAnonymous: true,
          },
          {
            uri: cfg.api + '/version',
            allowAnonymous: true,
          },
          cfg.api,
          cfg.api + '/*',
          `https://${cfg.auth.domain}/api/v2/*`,
          window.location.origin,
        ],
      },
    };
  }

  /**
   * Response with OEM id, for assets directory and database injection
   */
  static getIdConfig(): string {
    const cfg = env.oem.find((item) => item.domain === window.location.origin);
    return cfg.id;
  }

  static getApiConfig(): string {
    const cfg = env.oem.find((item) => item.domain === window.location.origin);
    return cfg.api;
  }

  static getApiAuthConfig(): string {
    const cfg = env.oem.find((item) => item.domain === window.location.origin);
    return 'auth-' + cfg.api;
    // return cfg.api;
  }

  static getApiInvoiceConfig(): string {
    const cfg = env.oem.find((item) => item.domain === window.location.origin);
    return 'invoice-' + cfg.api;
    // return cfg.api;
  }

  /**
   * Get information about title
   */
  static getTitleConfig(): string {
    const cfg = env.oem.find((item) => item.domain === window.location.origin);
    return cfg.title;
  }
}
