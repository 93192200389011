import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  catchError,
  filter,
  finalize,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TreeNodeService } from './TreeNodeService';
import { TreeNodeAction } from './models/TreeNodeAction';
import { TreeNodeActionType } from './models/TreeNodeActionType';
import { TreeNode } from './models/TreeNode';
import { TreeNodeRefreshOptions } from './models/TreeNodeRefreshOptions';
import { sortTreeNodes } from './utils/sortTreeNodes';
import { PresetTreeElementsService } from './preset-tree-elements.service';
import { TreeService } from '../../tree.service';
import { PrivilegesService } from '../../../../shared/services/privileges.service';
import { LayoutService } from '../../../../layout/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SkeletonComponent } from '../../../../shared/components/skeleton/skeleton.component';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { TreeHeaderButtonsComponent } from './tree-header-buttons/tree-header-buttons.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-tree-async',
  templateUrl: './tree-async.component.html',
  styleUrls: ['./tree-async.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TreeHeaderButtonsComponent, TreeNodeComponent, SkeletonComponent],
})
export class TreeAsyncComponent implements OnInit, OnDestroy {
  @Input() set treeNodeService(value: TreeNodeService) {
    this._treeNodeService = value;
    this.setOptionsSubs();
  }

  get treeNodeService(): TreeNodeService {
    return this._treeNodeService;
  }

  private _treeNodeService: TreeNodeService;

  loading = false;

  rootNode: TreeNode;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private treeService: TreeService,
    private cd: ChangeDetectorRef,
    private privilegesService: PrivilegesService,
    private presetElements: PresetTreeElementsService,
    private layoutService: LayoutService,
  ) {
    this.layoutService.organizationChanged$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.updateAllNodes();
    });
  }

  // = new PresetTreeElements();

  private handleNodeAction(action: TreeNodeAction) {
    switch (action.action) {
      case TreeNodeActionType.ON_CLICK:
        this.onNodeClick(action.node);
        break;
      case TreeNodeActionType.ON_EXPAND:
      case TreeNodeActionType.ON_INIT:
        //TODO this causes too many recursion is it needed? probably not
        // this.cd.detectChanges();
        break;
    }
    // this.updateAllNodes();
  }

  setOptionsSubs() {
    this._treeNodeService.nodeAction$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action) => this.handleNodeAction(action));
  }

  ngOnInit() {
    const root$: Observable<TreeNode[]> = <Observable<TreeNode[]>>(
      this.treeNodeService.refresh$
        .pipe(filter((refresh) => refresh.mode == TreeNodeRefreshOptions.ALL))
        .pipe(
          tap(() => this.clearCache()),
          startWith(null),
          tap(() => (this.loading = true)),
          tap(() => this.privilegesService.checkOrganizationStatus()),
          switchMap(() =>
            this.treeService.getTreeList({ root_only: true }).pipe(
              finalize((): void => {
                this.loading = false;
              }),
            ),
          ),
          tap((data) => {
            this.layoutService.treeItemsCount$.next(data.data.length);
          }),
          map((o: any) => {
            return <TreeNode[]>[...this.presetElements.getPresetElements(), ...o.data];
          }),
          //refresh all children to decide if we're displaying preset nodes
          // delayWhen(() => this.presetElements.refreshChildrenCount()),
          map((tree: TreeNode[]) => sortTreeNodes(tree)),
          tap(() => {
            this.rootNode.item_name = this.getRootItemName();
            this.rootNode.pointingUrl = `/organization/${this.privilegesService.organization()?.id}`;
            this.loading = false;
          }),
          // retry({ count: 1, delay: () => timer(1000) }), //retry once on error after second
          catchError((error: any) => {
            console.error('Failed to get tree list:', error);
            return of(null);
          }),
        )
    );

    this.rootNode = {
      item_name: this.getRootItemName(),
      presetChildren: root$,
      is_authorized: true,
      is_end_type: false,
      isPreSetElement: true,
      id: this.presetElements.rootId,
      pointingUrl: `/organization/${this.privilegesService.organization()?.id}`,
      icon: 'folder',
      role: this.privilegesService.isOrganizationAdmin() ? 'Edit' : null,
    };
  }

  private getRootItemName(): string {
    return this.privilegesService.organization()?.company_name;
  }

  clearCache() {
    this.treeNodeService.cachedNodes = [];
  }

  onNodeClick(node: TreeNode) {
    if (this.treeNodeService.filterOptions.moveNodeFilter != null) return;
    if (this.treeNodeService.config?.selectNodes && node) {
      this.treeNodeService.selectedNodeId$.next(node.id);
      this.updateAllNodes();
    }
  }

  private updateAllNodes() {
    this.treeNodeService.refresh$.next({ mode: TreeNodeRefreshOptions.VIEW });
  }

  @HostListener('window:beforeunload', ['$event'])
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
