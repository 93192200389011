import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  isDevMode,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  Observable,
  skip,
  skipWhile,
  Subject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { UplotRange } from './models/UplotRange.model';
import { UplotData } from './models/UplotData.model';
import { UplotInputData } from './models/UplotInputData.model';
import { UplotResponseData } from './models/UplotResponseData.model';
import { UplotSeriesConfig } from './models/UplotSeriesConfig.model';
import { UplotOnRescaleData, uplotRescalePlugin } from './plugins/uplotPluginRescale';
import { isIntervalAvailableForRange, uplotGetScaleMode } from './config/uplotScales';
import { getRangeString } from './utils/uplotUtil';
import { uplotSetNewSeries, uplotToggleSeriesFill } from './config/uplotSeriesConfiguration';
import { uplotCreateLoadingCircle } from './utils/uplotLoadingCircle';
import { uplotMakeChart, UplotMakeOpts } from './uplotMakeUplot';
import { uplotValidateData } from './utils/uplotValidateData';
import { setTranslationServiceForScrollMessage } from './utils/uplotShowMessage';
import { TranslateService } from '@ngx-translate/core';
import { GraphButtonsOpts, GraphButtonsComponent } from './graph-buttons/graph-buttons.component';
import { uplotFormatDataTimezoneToLocal } from './utils/uplotFormatTimezoneToLocal';
import { DataDisplayDefaultRanges } from '../../../models/DataDisplayDefaultRanges';
import { userDefinedRangePlugin } from './plugins/uplotDefinedRangePlugin';
import { getRangeFromDataDisplayDefaultRanges } from '../../../utils/getRangeFromDataDisplayDefaultRanges';
import { FormatValuePipe } from '../../../pipes/format-value.pipe';
import { DatePipe, NgIf } from '@angular/common';
import { LocaleSessionService } from '../../../services/localeSession.service';
import { formatDataToScale, getScaleForApi } from './utils/formatDataToScale';
import { UplotRangePickerComponent } from '../uplot-range-picker/uplot-range-picker.component';
import { DataRequestDto } from '../../../../../api-main';
import { ScaleSettingsComponent } from './scale-settings/scale-settings.component';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  standalone: true,
  imports: [NgIf, GraphButtonsComponent, UplotRangePickerComponent, ScaleSettingsComponent],
  providers: [FormatValuePipe, DatePipe],
})
export class GraphComponent implements AfterViewInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private formatValuePipe: FormatValuePipe,
    private datePipe: DatePipe,
    private localeSessionsService: LocaleSessionService,
    private cd: ChangeDetectorRef,
  ) {
    setTranslationServiceForScrollMessage(translateService);
  }

  @Input() data: UplotInputData;

  @Input() defaultRange: DataDisplayDefaultRanges = DataDisplayDefaultRanges.CURRENT_DAY;

  @Input() showSettings: boolean = true;

  @Input() showRange: boolean = true;

  @Input() uniqueId: string = 'defaultUplotGraph';

  @Output() graphLoaded = new EventEmitter<void>();

  public title: string = 'Graph';

  public rangeInHours: number;

  @Input() currentScale$: BehaviorSubject<DataRequestDto.ScaleEnum> = new BehaviorSubject(
    DataRequestDto.ScaleEnum.AUTO,
  );

  private loadedScale: DataRequestDto.ScaleEnum;

  @Input() userDefinedRange$: BehaviorSubject<DataDisplayDefaultRanges> = new BehaviorSubject(null);

  @Input() customDefaultRange: UplotRange;

  @Input() seriesConfig$: Subject<UplotSeriesConfig[]> = new Subject<UplotSeriesConfig[]>();

  @Input() refresh$: Subject<void> = new Subject<void>();

  @Input() showScaleSettings: boolean;

  @Input() showCustomRangesSettings: boolean = true;

  public getRangeString = getRangeString;

  public loaded = false;

  private seriesConfig: UplotSeriesConfig[];

  private readonly uplotUserConfigLSKey = 'uplot-user-configuration';

  private _uplot;

  private unsubscribe = new Subject<void>();

  private loadedRange: UplotRange;

  private dataRequested$ = new Subject<void>();

  public currentRange: UplotRange;

  preventRefreshing = false;

  preventRefreshingOnRescale = false;

  @ViewChild('uplotContainer')
  private container: ElementRef;

  get uplot() {
    return this._uplot;
  }

  get seriesFilled() {
    return this.uplot._userConfig.fillSeries;
  }

  getButtonsOpts(): GraphButtonsOpts {
    if (!this.currentRange || !this.calcRangeInHours()) return null;
    return {
      uplot: this.uplot,
      currentRange: this.currentRange,
      title: this.title,
      calcRangeInHours: this.calcRangeInHours.bind(this),
      currentScale$: this.currentScale$,
      userDefinedRange$: this.userDefinedRange$,
      showCustomRangesSettings: this.showCustomRangesSettings, //TODO no reference after updating range in hours
      refresh$: this.refresh$,
    };
  }

  setUplotRange(range: UplotRange) {
    this.setRange(DataDisplayDefaultRanges.CUSTOM, range);
  }

  setRange(defaultRange: DataDisplayDefaultRanges, range: UplotRange = null) {
    this.userDefinedRange$.next(defaultRange);
    if (range == null || defaultRange != DataDisplayDefaultRanges.CUSTOM) {
      this.currentRange = getRangeFromDataDisplayDefaultRanges(this.userDefinedRange$.value);
    } else {
      this.currentRange = range;
    }
    this.uplot?.setScale('x', this.currentRange);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setupRange();
      if (!this.data) throw new Error('data is undefined!');
      this.init();
      this.initManualScaleChange();
      this.initSeriesChange();
      this.initRefresh();
    }, 0);
  }

  private setupRange() {
    this.userDefinedRange$.next(this.defaultRange);
    if (this.userDefinedRange$.value !== DataDisplayDefaultRanges.CUSTOM)
      this.loadedRange = getRangeFromDataDisplayDefaultRanges(this.userDefinedRange$.value);

    //custom range

    if (this.customDefaultRange) this.loadedRange = this.customDefaultRange;

    if (this.loadedRange == undefined)
      this.loadedRange = getRangeFromDataDisplayDefaultRanges(DataDisplayDefaultRanges.CURRENT_DAY);

    if (this.currentRange == undefined) this.currentRange = this.loadedRange;
  }

  private initManualScaleChange() {
    this.currentScale$
      .pipe(takeUntil(this.unsubscribe))
      .pipe(skip(1))
      .subscribe(() => {
        this.refreshData();
      });
  }

  private refreshData() {
    if (this.preventRefreshing) return;
    const u = this.uplot;
    if (u == undefined) return timer(100).subscribe(() => this.refreshData());
    this.preventRefreshingOnRescale = true;
    u.loadingElement.show();
    this.setLoadedRange();

    this.getData()
      .pipe(
        take(1),
        finalize(() => {
          u.redraw();
          u.loadingElement.hide();
        }),
      )
      .subscribe((data: UplotData) => {
        if (!data) return;
        u.setData(data);
        // u.redraw();
        // u.loadingElement.hide();
        setTimeout(() => (this.preventRefreshingOnRescale = false), 200);
      });
  }

  private initSeriesChange() {
    this.seriesConfig$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.setSeries(data);
    });
  }

  private initRefresh() {
    this.refresh$.pipe(debounceTime(100), takeUntil(this.unsubscribe)).subscribe(() => {
      this.refreshData();
    });
  }

  private additionalRange(): number {
    if (!this.rangeInHours) return 24 * 3600;
    return this.rangeInHours * 3600;
  }

  private rangeOffsetToLoadNewData(): number {
    return (this.rangeInHours * 3600) / 3;
  }

  private isDataWithinTheScale(): boolean {
    if (!this.loadedRange || !this.currentRange) return true;
    const maxDifference = this.rangeOffsetToLoadNewData();
    if (
      this.loadedRange.min == this.currentRange.min &&
      this.loadedRange.max == this.currentRange.max
    )
      return true;

    return (
      this.loadedRange.min < this.currentRange.min - maxDifference &&
      this.loadedRange.max > this.currentRange.max + maxDifference
    );
  }

  private init(): void {
    const loadingEl = uplotCreateLoadingCircle(this.container.nativeElement);
    loadingEl.show();
    this.preventRefreshingOnRescale = true;
    this.getData().subscribe((data) => {
      this.initUplot(data);
      loadingEl.destroy();

      this.loaded = true;
      this.preventRefreshingOnRescale = false;
      this.graphLoaded.emit();
    });
  }

  private initUplot(data: UplotData) {
    this._uplot = this.makeChart(data);
    this.currentRange = this.uplot.scales.x;
    setTimeout(() => this.calcRangeInHours(), 0);
  }

  private makeChart(data: UplotData) {
    const opts: UplotMakeOpts = {
      data,
      container: this.container.nativeElement,
      title: this.title,
      seriesConfig: this.seriesConfig,
      plugins: [this.setDataOnRescale(), userDefinedRangePlugin(this.userDefinedRange$)],
      valueFormatFunction: this.formatValuePipe.transform,
      dateFormatFunction: (val) =>
        this.datePipe.transform(
          val,
          'short',
          this.localeSessionsService.timezone,
          this.localeSessionsService.locale,
        ),
      localeService: this.localeSessionsService,
      translateService: this.translateService,
    };
    const u = uplotMakeChart(opts);
    u.container = this.container.nativeElement;
    u.uniqueId = this.uniqueId;
    u._userConfig = this.getUplotConfig();
    setTimeout(() => uplotToggleSeriesFill(u, this.seriesFilled), 0);
    u.setScale('x', this.loadedRange);

    return u;
  }

  private setDataOnRescale() {
    const onRescale: UplotOnRescaleData = new Subject();
    const cancelLastRequest = new Subject<void>();
    onRescale
      .pipe(
        filter(() => this.preventRefreshing == false && this.preventRefreshingOnRescale == false),
        skip(1), //skip first event after setting graph - consider starting listening only after graph is fully loaded
        debounceTime(400),
        skipWhile(() => this.preventRefreshing || this.preventRefreshingOnRescale),
        takeUntil(this.unsubscribe),
      )
      .subscribe((rescale) => {
        this.currentRange = { min: rescale.scalesX.min, max: rescale.scalesX.max };
        this.cd.detectChanges();
        this.calcRangeInHours();

        const scale = uplotGetScaleMode(this.rangeInHours);
        const scaleChanged =
          this.loadedScale != scale && this.currentScale$.value === DataRequestDto.ScaleEnum.AUTO;
        const getNewData = scaleChanged || !this.isDataWithinTheScale();

        // this.saveCache();

        if (!getNewData) {
          cancelLastRequest.next();
          rescale.data.next(null);
          return;
        }

        this.setLoadedRange();
        cancelLastRequest.next();
        rescale.data.next(this.getData());
      });

    return uplotRescalePlugin(onRescale, cancelLastRequest, this.unsubscribe);
  }

  private calcRangeInHours() {
    this.rangeInHours = (this.currentRange.max - this.currentRange.min) / 3600;
    return this.rangeInHours;
  }

  private setLoadedRange(): void {
    this.loadedRange.min = this.currentRange.min - this.additionalRange();
    this.loadedRange.max = this.currentRange.max + this.additionalRange();
  }

  private setSeries(seriesConfig: UplotSeriesConfig[]) {
    this.seriesConfig = seriesConfig;
    if (this.uplot) {
      uplotSetNewSeries(this.uplot, seriesConfig);
      uplotToggleSeriesFill(this.uplot, this.seriesFilled);
    }
  }

  private getData(): Observable<UplotData> {
    this.calcRangeInHours();
    const scale = this.getScaleForRequest();
    const scaleForApi = getScaleForApi(scale);

    this.dataRequested$.next();

    if (this.userDefinedRange$.value !== DataDisplayDefaultRanges.CUSTOM)
      this.loadedRange = getRangeFromDataDisplayDefaultRanges(this.userDefinedRange$.value);

    const requestRange = this.loadedRange;

    const responseData: Observable<UplotResponseData> = this.data(scaleForApi, requestRange);
    return responseData.pipe(
      take(1),
      tap((response: UplotResponseData) => {
        uplotValidateData(response);
        this.setSeries(response.series);
        this.loadedScale = scale;
      }),
      map((response: UplotResponseData) => {
        const timezoneFormatted = uplotFormatDataTimezoneToLocal(response.data);

        return formatDataToScale({
          data: timezoneFormatted,
          scale: scale,
          series: response.series,
        });
      }),
      // tap(() => this.graphLoaded.emit()),
      // retry({ count: 1, delay: () => timer(1000) }), //retry twice on error after second
      catchError((err) => {
        if (isDevMode()) console.warn('Failed to download graph data: ', err, 'Retrying');
        // if (--attempt > 0) return caught;
        throw new Error('Failed to download graph data: ' + err);
      }),
      takeUntil(this.dataRequested$),
      takeUntil(this.unsubscribe),
    );
  }

  private getScaleForRequest() {
    let scale;
    if (this.currentScale$.value === DataRequestDto.ScaleEnum.AUTO) {
      scale = uplotGetScaleMode(this.rangeInHours);
    } else {
      if (!isIntervalAvailableForRange(this.currentScale$.value, this.rangeInHours)) {
        this.preventRefreshing = true;
        this.currentScale$.next(uplotGetScaleMode(this.rangeInHours));
        this.preventRefreshing = false;
      }
      scale = this.currentScale$.value;
    }
    return scale;
  }

  private getUplotConfig() {
    const defaultConfig = {
      showTooltip: true,
      showTooltipExtData: false,
      fillSeries: true,
      showAvgLines: false,
      showMinLines: false,
      showMaxLines: false,
    };
    return defaultConfig;
    // try {
    //   const config = JSON.parse(localStorage.getItem(this.uplotUserConfigLSKey));
    //   return Object.assign(defaultConfig, config);
    // } catch (e) {
    //   return defaultConfig;
    // }
  }

  ngOnDestroy(): void {
    if (this.uplot) this.uplot.destroy();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
