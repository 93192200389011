import {
  ChangeDetectionStrategy,
  Component,
  effect,
  isDevMode,
  NgZone,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { OrganizationService } from '../../../../pages/organization/organization.service';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { OrganizationDto } from '../../../../../api-main';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrganizationSelectModalComponent } from './organization-select-modal/organization-select-modal.component';
import { Router } from '@angular/router';
import { LayoutService } from '../../../layout.service';
import { PrivilegesService } from '../../../../shared/services/privileges.service';
import { WaitService } from '../../../../shared/services/wait.service';
import { NoOrganizationSelectedComponent } from './no-organization-selected/no-organization-selected.component';
import { DashboardService } from '../../../../pages/dashboard/services/dashboard.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatTooltipModule, MatIconModule, NgTemplateOutlet, TranslateModule],
})
export class OrganizationSelectComponent implements OnInit {
  private organizationStateBC = new BroadcastChannel('organization-state-bc');

  constructor(
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    private router: Router,
    private layoutService: LayoutService,
    private privilegesService: PrivilegesService,
    private waitService: WaitService,
    private ngZone: NgZone,
    private dashboardService: DashboardService,
  ) {
    effect(() => {
      this.organization = this.privilegesService.organization;
      if (this.loaded() == true) this.waitService.stop();
    });

    this.organizationStateBC.onmessage = (event) => {
      const newOrganization = event.data.organization;
      if (this.loaded() == true && newOrganization?.id != this.organization()?.id)
        this.ngZone.run(() => this.setOrganization(newOrganization, true));
    };

    this.privilegesService.checkOrganizationStatus$
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => this.organizationService.getState()),
      )
      .subscribe((val) => {
        if (!this.organization() || val.organization_id != this.organization().id)
          this.loadOrganization();
      });

    this.privilegesService.organizationModalTrigger$
      .pipe(takeUntilDestroyed())
      .subscribe((disableClose: boolean) => {
        this.openModal(disableClose);
      });
  }

  ngOnInit() {
    const organizationFromService = this.privilegesService.organization();
    if (organizationFromService) this.setOrganization(organizationFromService);
    else this.loadOrganization();
  }

  organization: WritableSignal<OrganizationDto> = signal(null);

  loaded = signal(false);

  loadOrganization(organization_id = undefined) {
    this.waitService.start();
    (organization_id === undefined ? this.organizationService.getState() : of({ organization_id }))
      .pipe(
        switchMap((data) =>
          data.organization_id ? this.organizationService.get(data.organization_id) : of(null),
        ),
        catchError((err) => {
          // this.loaded.set(true);
          console.error('Failed to load organization: ', err);
          return of(null);
        }),
      )
      .subscribe((data) => {
        if (data == null) this.checkOrganizationAccess();
        this.setOrganization(data, organization_id !== undefined);
      });
  }

  private checkOrganizationAccess() {
    this.organizationService.list().subscribe((organizations) => {
      if (organizations.cnt == 0) this.noOrganizationAccess();
    });
  }

  private setOrganization(data: OrganizationDto, reloadRoute: boolean = false) {
    // this.organization.set(data);
    this.privilegesService.organization.set(data);
    this.loaded.set(true);
    if (data == null) this.router.navigateByUrl('/profile').catch(console.warn);
    // else this.router.navigateByUrl('/tree/inspect').catch(console.warn);
    this.layoutService.selectedItem = null;

    // if (reloadRoute) this.reloadCurrentRoute();
    if (reloadRoute && data) this.router.navigateByUrl('/tree/inspect').catch(console.warn);
    // this.reloadCurrentRoute();

    this.waitService.stop();

    this.organizationStateBC.postMessage({ organization: data });
    this.dashboardService.updateFavDashboards$.next();
    this.layoutService.organizationChanged$.next();
    this.layoutService.organizationLoaded.set(true);
  }

  failedToLoadImg(err: any) {
    if (isDevMode()) console.log('img failed to load', err);
  }

  openModal(disableClose: boolean = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = disableClose;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(OrganizationSelectModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((val) => !!val),
        tap(() => this.waitService.start()),
        switchMap((result) => this.organizationService.setState(result.id).pipe(map(() => result))),
      )
      .subscribe({
        next: (result) => {
          this.loadOrganization(result.id ?? null);
          // console.log('-> result', result);
        },
        error: () => {
          this.loaded.set(true);
        },
      });
  }

  private noOrganizationAccess() {
    if (this.privilegesService.noOrganizationInfoDisplayed || this.dialog.openDialogs.length > 0)
      return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    // const dialogRef =
    this.dialog.open(NoOrganizationSelectedComponent, dialogConfig);
    this.privilegesService.noOrganizationInfoDisplayed = true;
  }
}
