import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DashboardItemSettingsComponent } from '../dashboard-item-settings.component';
import { DashboardItemDataGraph } from '../../models/DashboardItemDataGraph.model';
import { DataDisplayDefaultRanges } from '../../../../shared/models/DataDisplayDefaultRanges';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { GraphSettingsSeriesComponent } from './graph-settings-series/graph-settings-series.component';
import { EmuListInputComponent } from '../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { DashboardItemDataGraphTypesModel } from '../../models/DashboardItemDataGraphTypes.model';
import { EmuInputComponent } from '../../../../shared/components/inputs/emu-input/emu-input.component';
import { getDefaultSeries } from './graph-settings-series/getDefaultSeries';
import { maxSeriesForGraphType } from './graph-settings-series/maxSeriesForGraphType';
import { getAvailableRangesForGraphType } from './getAvailableRangesForGraphType';
import { SankeyChartConfigComponent } from './sankey-chart-config/sankey-chart-config.component';
import { SkeletonComponent } from '../../../../shared/components/skeleton/skeleton.component';
import { UplotRange } from '../../../../shared/components/graph-uplot/graph/models/UplotRange.model';
import { RangePickerComponent } from './range-picker/range-picker.component';

@Component({
  selector: 'app-dashboard-graph-settings',
  templateUrl: './dashboard-graph-settings.component.html',
  styleUrls: ['../../styles/widget-settings.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    EmuListInputComponent,
    GraphSettingsSeriesComponent,
    TranslateModule,
    EmuInputComponent,
    SankeyChartConfigComponent,
    SkeletonComponent,
    RangePickerComponent,
  ],
})
export class DashboardGraphSettingsComponent extends DashboardItemSettingsComponent {
  getDefinedRanges(data: DashboardItemDataGraph = this.data) {
    return [DataDisplayDefaultRanges.CUSTOM, ...getAvailableRangesForGraphType(data)];
  }

  isPiechart() {
    return [
      DashboardItemDataGraphTypesModel.PIE_MONTHLY_AGG,
      DashboardItemDataGraphTypesModel.PIE_WEEKLY_AGG,
      DashboardItemDataGraphTypesModel.PIE,
    ].includes(this.data.type);
  }

  isHeatmap() {
    return [
      DashboardItemDataGraphTypesModel.HEATMAP_DAILY,
      DashboardItemDataGraphTypesModel.HEATMAP,
    ].includes(this.data.type);
  }

  isSankey() {
    return [DashboardItemDataGraphTypesModel.SANKEY].includes(this.data.type);
  }

  isColorConfigurable() {
    return [
      DashboardItemDataGraphTypesModel.DEFAULT,
      DashboardItemDataGraphTypesModel.PIE,
    ].includes(this.data.type);
  }

  isDefaultChart() {
    return [DashboardItemDataGraphTypesModel.DEFAULT].includes(this.data.type);
  }

  public graphTypes = Object.keys(DashboardItemDataGraphTypesModel)
    .filter((o) => !isNaN(Number(o)))
    .map((o) => +o);

  override get data(): DashboardItemDataGraph {
    const data = this.itemData?.graph;
    this.dataValidator(data);

    return data;
  }

  private dataValidator(data: DashboardItemDataGraph) {
    if (!data) return;
    const maxSeriesLength = maxSeriesForGraphType(data.type);
    if (data.series.length > maxSeriesLength) {
      data.series.length = maxSeriesLength;
      // this.cd.detectChanges();
    }
    const availableRanges = this.getDefinedRanges(data);
    if (!availableRanges.includes(data.userDefinedRange) && availableRanges.length > 0) {
      data.userDefinedRange = availableRanges[0];
      // this.cd.detectChanges();
    }
  }

  protected override setDefault() {
    if (this.data == undefined)
      this.itemData.graph = {
        ready: false,
        showSettings: true,
        showRange: false,
        userDefinedRange: DataDisplayDefaultRanges.CURRENT_DAY,
        type: DashboardItemDataGraphTypesModel.DEFAULT,
        series: [getDefaultSeries()],
      };
    this.graphReady();
  }

  getRange(): UplotRange {
    return this.data.range;
  }

  changeRange(range: UplotRange) {
    this.data.range = range;
    this.reloadGraph();
  }

  graphReady() {
    this.itemData.graph.ready = true;
  }

  reloadGraph() {
    this.data.refresh = true;
  }

  refreshSeries() {
    this.data.seriesChanges = true;
  }

  public getRangeString(scale: string): string {
    // return `UPLOT.RANGE.${DataDisplayDefaultRanges[Number(scale)].toUpperCase()}`;
    return `UPLOT.RANGE.${scale.toUpperCase()}`;
  }

  public getTypeString(scale: number): string {
    return `DASHBOARD.GRAPH_TYPES.${DashboardItemDataGraphTypesModel[Number(scale)].toUpperCase()}`;
  }

  protected readonly of = of;

  protected readonly DataDisplayDefaultRanges = DataDisplayDefaultRanges;
}
