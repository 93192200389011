export function heatmapDailyPluginMouse() {
  // const factor = opts.factor || 0.75;
  return {
    hooks: {
      ready: (u) => {
        const over = u.over;
        // const rect = over.getBoundingClientRect();
        const drag = u.cursor.drag;

        const defaultCursor = over.style.cursor;
        over.addEventListener('mousedown', (e) => {
          if (u.loadingElement.isBlocked()) return;
          if (e.button == 1 || e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();

            if (u.cursor.drag.x == true) drag._x = true;
            drag.x = false;
            const top0 = e.clientY;

            // console.log('=>(heatmapDailyPluginMouse.ts:21) u.scales.y', u.scales.y);
            const scYMin0 = u.scales.y.min;
            const scYMax0 = u.scales.y.max;

            const xUnitsPerPx = u.posToVal(1, 'x') - u.posToVal(0, 'x');

            over.style.cursor = 'grabbing';

            function onMove(event) {
              event.preventDefault();

              //block if loading?
              if (u?.loadingElement?.isBlocked()) onUp();

              const top1 = event.clientY;

              const dy = xUnitsPerPx * (top1 - top0);
              const step = 0.5;

              let nMin = scYMin0 - dy;
              let nMax = scYMax0 - dy;

              nMin = Math.floor(nMin / step) * step;
              nMax = Math.floor(nMax / step) * step;

              u.setScale('y', {
                min: nMin,
                max: nMax,
              });

              u.redraw();
            }

            function onUp() {
              document.removeEventListener('mousemove', onMove);
              document.removeEventListener('mouseup', onUp);
              over.style.cursor = defaultCursor;
              drag.x = drag._x;
            }

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
          }
        });

        // let scrollMessageShown = false;
        over.addEventListener('wheel', (e) => {
          e.preventDefault();
          // if (e.buttons == 4) e.preventDefault();
        });
      },
    },
  };
}
