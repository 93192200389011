/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MetadataGetDto {
  /**
   * entity_type, deprecated - all is stored as TREE_NODE
   */
  entity_type: MetadataGetDto.EntityTypeEnum;
  /**
   * entity_id - pointing on tree item id
   */
  entity_id: number;
  /**
   * If entity_id is null we can try to find this information based on logger_id
   */
  logger_id: number;
  /**
   * entity_id - deprecated. Used for store metadata in logger device or logger value
   */
  entity_parent_id?: number;
}
export namespace MetadataGetDto {
  export type EntityTypeEnum = 'LOGGER' | 'DEVICE' | 'DEVICE_VALUE' | 'TREE_NODE';
  export const EntityTypeEnum = {
    LOGGER: 'LOGGER' as EntityTypeEnum,
    DEVICE: 'DEVICE' as EntityTypeEnum,
    DEVICEVALUE: 'DEVICE_VALUE' as EntityTypeEnum,
    TREENODE: 'TREE_NODE' as EntityTypeEnum,
  };
}
