import { DataRequestValueItemDto } from '../../../../../api-main';
import { SeriesGraphSettingsModel } from '../../models/SeriesGraphSettings.model';
import { UplotSeriesConfig } from '../../../../shared/components/graph-uplot/graph/models/UplotSeriesConfig.model';

export function getSeriesForRequest(
  series: Array<SeriesGraphSettingsModel>,
): Array<DataRequestValueItemDto> {
  return (series ?? [])
    .filter((o) => o.loggerId != null && o.valueId != null)
    .map((s) => ({
      logger_id: s.loggerId,
      value_id: s.valueId,
      consumption: s.consumption,
    }));
}

export function getSeriesConfig(
  series: Array<SeriesGraphSettingsModel>,
  downloadedSeries: Array<UplotSeriesConfig>,
): Array<UplotSeriesConfig> {
  return series
    .filter((s: SeriesGraphSettingsModel) => s.loggerId != null && s.valueId != null)
    .map((s: SeriesGraphSettingsModel, index: number) => ({
      ...s,
      label: s.label && s.label != '' ? s.label : downloadedSeries[Number(index)]?.label,
      pathStyle: s.pathStyle ?? downloadedSeries[Number(index)]?.pathStyle,
      stroke: s.stroke ?? downloadedSeries[Number(index)]?.stroke,
      unit: s.unit && s.unit != '' ? s.unit : downloadedSeries[Number(index)]?.unit,
    }));
}
