import { DeviceValuesDto } from '../../../../../../../api-main';
import { TranslateService } from '@ngx-translate/core';

export function getDeviceParameterNameSetTranslate(translateService: TranslateService) {
  return getDeviceParameterName.bind(translateService);
}

//This function requires to be binded to componend with injected service translateService:TranslateService
function getDeviceParameterName(parameter: DeviceValuesDto) {
  const translate: TranslateService = this;
  if (!parameter) return '';
  if (!translate)
    throw new Error('This function requires to be binded to componend with translateService');
  // console.log('=>(getDeviceParameterName.ts:4) parameter', parameter);
  let tariff = '' + parameter.cfg_tariff ?? parameter.tariff;
  let phase: string = '' + parameter.cfg_phase;
  if (phase && !isNaN(Number(phase)) && Number(phase) > 0) {
    phase = `${translate.instant('LOGGER.PHASE')}: ${phase} `;
  } else phase = '';

  if (tariff && !isNaN(Number(tariff)) && Number(tariff) > 0) {
    tariff = `${translate.instant('LOGGER.TARIFF')}: ${tariff}`;
  } else tariff = '';

  return `${parameter.description_str} ${phase}${tariff}`;
}
