import { heatmapHourlyCursor } from '../hourly/heatmapHourlyCursor';

export function heatmapDailyCursor() {
  const cursor: any = heatmapHourlyCursor();

  cursor.drag = {
    dist: 10,
    y: false,
    x: false,
    _x: false,
  };

  return cursor;
}
