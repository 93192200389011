import { TreeNode } from '../models/TreeNode';
import { PresetNodeTypes } from '../../tree-node-form/tree-form-select-node-type/TreeNodeTypes';

export function getNodeUrl(item: TreeNode): string {
  const type: PresetNodeTypes = <PresetNodeTypes>item.item_type_code;
  // let url = this.selectedItem.pointingUrl;
  if (item.pointingUrl) return item.pointingUrl;

  switch (type) {
    case 'LOGGER':
    case 'LORA':
    case 'IOT':
      // return `logger/${item.item_id ?? 0}`;
      return `/logger/logger`;
    case 'DASHBOARD':
      return `/dashboards/${item.item_id}`;
    case 'FILE':
    case 'FOLDER':
    case 'OTHER':
    default:
      return '/tree/inspect';
  }
}
