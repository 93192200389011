import { Component, Input } from '@angular/core';
import { TreeNodeService } from '../../TreeNodeService';
import { TreeNode } from '../../models/TreeNode';
import { TreeNodeActionType } from '../../models/TreeNodeActionType';
import { PresetTreeElementsService } from '../../preset-tree-elements.service';
import { PrivilegesService } from '../../../../../../shared/services/privileges.service';
import { DisabledDirective } from '../../../../../../shared/directives/disabled.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-tree-node-actions',
  templateUrl: './tree-node-actions.component.html',
  styleUrls: ['./tree-node-actions.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, DisabledDirective],
})
export class TreeNodeActionsComponent {
  @Input() treeNodeService: TreeNodeService;

  @Input() node: TreeNode;

  constructor(
    private presetElements: PresetTreeElementsService,
    private privilegesService: PrivilegesService,
  ) {}

  showAddButton() {
    return (
      !this.node.is_end_type &&
      (!this.node.isPreSetElement ||
        (this.node.id == this.presetElements.rootId &&
          this.privilegesService.isOrganizationAdmin()))
    );
  }

  showEditButton() {
    return !this.node.isPreSetElement;
  }

  showActionButtons(): boolean {
    return (
      this.treeNodeService.config?.displayActionButtons &&
      this.privilegesService.hasEditPrivileges(this.node)
    );
  }

  isRootItemForNotAdminUser() {
    return this.node.root_item_response && !this.privilegesService.isOrganizationAdmin();
  }

  showMoveButton(): boolean {
    if (this.isRootItemForNotAdminUser()) return false;
    return !this.node.isPreSetElement;
  }

  showDeleteButton(): boolean {
    //TODO to discuss, currently blocked only on frontend
    if (this.isRootItemForNotAdminUser()) return false;
    return !this.node.isPreSetElement;
  }

  blockDeleteButton() {
    return false; // this.node.children_count > 0;
  }

  addButtonClicked() {
    this.action(TreeNodeActionType.ON_ADD_CLICKED);
  }

  editButtonClicked() {
    this.action(TreeNodeActionType.ON_EDIT_CLICKED);
  }

  moveButtonClicked() {
    if (this.treeNodeService.config.moveHandling) return;
    if (this.isMoveActive()) this.action(TreeNodeActionType.ON_MOVE_ENDED);
    else this.action(TreeNodeActionType.ON_MOVE_START);
  }

  isMoveActive() {
    return this.treeNodeService.filterOptions.moveNodeFilter != null;
  }

  isThisNodeMoved() {
    return this.treeNodeService.filterOptions.moveNodeFilter.id == this.node.id;
  }

  deleteButtonClicked() {
    if (this.blockDeleteButton()) return;
    this.action(TreeNodeActionType.ON_DELETE_CLICKED);
  }

  action(action: TreeNodeActionType) {
    if (!this.treeNodeService?.nodeAction$) return;
    this.treeNodeService.nodeAction$.next({ node: this.node, action });
  }
}
