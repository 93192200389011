/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SeriesDataStructureDto {
  /**
   * Id
   */
  logger_id?: number;
  /**
   * Id
   */
  device_id?: number;
  /**
   * Id
   */
  device_value_id?: number;
  /**
   * Id
   */
  name?: string;
  /**
   * obis code
   */
  obis_code?: string;
}
