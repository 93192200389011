/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SingleDataItemDto {
  /**
   * request id
   */
  id?: string;
  /**
   * Logger identifier
   */
  logger_id: number;
  /**
   * Value data identifier
   */
  value_id: number;
  /**
   * Range start
   */
  range_start: number;
  /**
   * Range end
   */
  range_end: number;
  /**
   * Value mode
   */
  mode: SingleDataItemDto.ModeEnum;
  /**
   * Is consumption
   */
  consumption: boolean;
}
export namespace SingleDataItemDto {
  export type ModeEnum = 'avg' | 'last' | 'min' | 'max' | 'total';
  export const ModeEnum = {
    Avg: 'avg' as ModeEnum,
    Last: 'last' as ModeEnum,
    Min: 'min' as ModeEnum,
    Max: 'max' as ModeEnum,
    Total: 'total' as ModeEnum,
  };
}
