import { Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { ParentCord } from './model/IParentCord';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user-guide',
  standalone: true,
  templateUrl: './user-guide.component.html',
  styleUrl: './user-guide.component.scss',
  imports: [NgIf, MatIcon, MatIconButton, TranslateModule],
})
export class UserGuideComponent {
  public id: number;

  public content: WritableSignal<{ name: string; isVisible: WritableSignal<boolean> }> =
    signal(null);

  public position: WritableSignal<'top' | 'bottom' | 'left' | 'right'> = signal('top');

  public parentCord: WritableSignal<ParentCord> = signal(undefined);

  public display: WritableSignal<boolean> = signal(true);

  private offset: number = 30;

  public tooltipYPosition: Signal<string> = computed(() => {
    if (this.position() === 'top') {
      return `${this.parentCord().y - this.parentCord().height - this.offset}px `;
    }
    if (this.position() === 'bottom') {
      return `${this.parentCord().bottom + this.offset}px `;
    }
    return `${this.parentCord().top}px `;
  });

  public tooltipXPosition: Signal<string> = computed(() => {
    if (this.position() === 'right') {
      return `${this.parentCord().x + this.parentCord().width + this.offset}px`;
    }
    if (this.position() === 'left') {
      return `${this.parentCord().left - document.getElementById(`user-guide-${this.id}`).offsetWidth - this.offset}px`;
    }
    return `${this.parentCord().x - document.getElementById(`user-guide-${this.id}`).offsetWidth / 2}px`;
  });

  public tooltipVisibility = computed(() => this.content()?.isVisible() && this.display());

  public arrowPosition: Signal<string> = computed(() => {
    return `user-guide-tooltip--${this.position()}-arrow`;
  });

  public hideTooltip(): void {
    this.display.set(false);
  }
}
