/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TreeItemTypeLngDto } from './treeItemTypeLngDto';

export interface TreeItemTypeDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item type identification
   */
  id?: number;
  /**
   * Item type icon
   */
  icon?: string;
  /**
   * Allowed item types to connect with item type. If null - all types allowed
   */
  item_type_allowed?: Array<number>;
  /**
   * Check if children not possible
   */
  end_type?: boolean;
  /**
   * List captions in different languages
   */
  items: Array<TreeItemTypeLngDto>;
  /**
   * Item type code
   */
  code: TreeItemTypeDto.CodeEnum;
}
export namespace TreeItemTypeDto {
  export type CodeEnum = 'LOGGER' | 'LORA' | 'FOLDER' | 'DASHBOARD' | 'FILE' | 'IOT';
  export const CodeEnum = {
    LOGGER: 'LOGGER' as CodeEnum,
    LORA: 'LORA' as CodeEnum,
    FOLDER: 'FOLDER' as CodeEnum,
    DASHBOARD: 'DASHBOARD' as CodeEnum,
    FILE: 'FILE' as CodeEnum,
    IOT: 'IOT' as CodeEnum,
  };
}
