/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GpListResponseItemDto } from './gpListResponseItemDto';

export interface GpListResponseDto {
  /**
   * HTTP response code
   */
  code: number;
  /**
   * Has access
   */
  has_access: boolean;
  /**
   * Response list
   */
  data: Array<GpListResponseItemDto>;
}
