export function uplotCreateLoadingCircle(target, u = null): UplotLoadingElement {
  const element = document.createElement('div');
  const loadingCircle = document.createElement('div');
  element.className = 'u-loading';
  loadingCircle.className = 'u-loading-circle unlocked';

  element.appendChild(loadingCircle);
  target.appendChild(element);

  element.addEventListener('wheel', (e) => e.preventDefault());
  let visible = false;
  let blocked = false;

  hide();
  return { element, hide, show, isVisible, isBlocked, destroy, block };

  function hide() {
    element.style.display = 'none';
    if (u) {
      u.cursor.drag.x = u.cursor.drag._x;
    }
    visible = false;
    blocked = false;
    element.classList.toggle('unlocked', true);
  }
  function show() {
    element.style.display = '';
    if (u) {
      u.cursor.drag.x = false;
    }
    visible = true;
  }
  function isVisible() {
    return visible;
  }

  function isBlocked() {
    return isVisible() && blocked;
  }

  function block() {
    element.classList.toggle('unlocked', false);
    blocked = true;
  }

  function destroy() {
    if (u) {
      u.cursor.drag.x = !!u.cursor.drag._x;
    }
    element.parentElement.removeChild(element);
  }
}

export interface UplotLoadingElement {
  element: HTMLElement;
  hide: Function;
  show: Function;
  isVisible: Function;
  isBlocked: Function;
  block: Function;
  destroy: Function;
}
